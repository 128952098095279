const template = `
{{?!!it.audicode}}
	{{?!!it.configuration}}
		{{? SETUPS.get('scope.nemo.audicode.externallink')}}
			{{var target = "target='blank'";}}
		{{??}}
			{{var target = "";}}
		{{?}}
		{{var link = '/' + it.audicode;}}
		{{?!!it.configuration.extColorCode && !!it.configuration.extColorCode.selected}}
			{{var selected = it.configuration.extColorCode.selected;}}
			{{var backgroundcolor = 'extcolor--' + selected;}}
			{{var textcolor = 'extcolor-text--' + selected;}}
		{{??}}
			{{var selected = "";}}
			{{var backgroundcolor = "";}}
			{{var textcolor = "";}}
		{{?}}
		<audi-configurator-search-audicode class="search-audicode" data-module="search-audicode">
			<div class="search-audicode__top">
				<div class="search-audicode__background-color-container {{=backgroundcolor}}"></div>
				<div class="search-audicode__text-container {{=textcolor}}">
					<div class="search-audicode__audicode audi-copy-s">{{=window.i18n['search-layer.your-audicode']}} {{=it.audicode}}</div>
					{{?!!it.configuration}}
						{{?!!it.configuration.carlineName}}
							<h3 class="audi-headline-order-3 search-audicode__carline">{{=it.configuration.carlineName}}</h3>
						{{?}}
						{{?!!it.configuration.description}}
							<div class="audi-copy-s search-audicode__engine">{{=it.configuration.description}}</div>
						{{?}}
						{{?!!it.configuration.price}}
							<div class="audi-copy-m search-audicode__price">{{=it.configuration.price}}</div>
						{{?}}
					{{?}}
				</div>
			</div>

			<div class="search-audicode__middle">
				<div class="search-audicode__background-color-container search-audicode__background-color-container--size60 {{=backgroundcolor}}"></div>
				<div class="search-audicode__image-container">
					{{?!!it.configuration && it.images && it.images['exterior'] && it.images['exterior']['flyout']}}
						<picture>
							<source media="(max-width: 427px)" srcset="{{=it.mediaservice}}{{=it.images['exterior']['flyout']}}?width=470">
							<img class="search-audicode__image" src="{{=it.mediaservice}}{{=it.images['exterior']['flyout']}}?width=654">
						</picture>
					{{??}}
						<img class="search-audicode__image" src="{{=SETUPS.get('nemo.url.assets')+"/img/fallback/fb-audicode-flyout.png"}}" alt="Fallback Image">
					{{?}}
				</div>
			</div>

			<div class="search-audicode__text-container search-audicode__bottom">
				<div>
					<a href="{{=link}}" {{=target}} class="audi-j-quick-search-result-tracking audi-link-m audi-link-m--arrow search-audicode__link">
						<span class="audi-link-m__text">{{=window.i18n['search-layer.load-configuration']}}</span>
						<svg class="audi-link-m__icon">
							<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
						</svg>
					</a>
				</div>
				<p class="audi-copy-s search-audicode__reference">
					<span class="search-audicode__reference-label">{{=window.i18n['search-layer.label.disclaimer']}}:</span>
					<span class="search-audicode__reference-text">{{=window.i18n['search-layer.configuration-overwrite-warning']}}</span>
				</p>
				{{? !SETUPS.get('scope.nemo.hide.consumption-entries')}}
					{{? it.configuration.techdata && (it.configuration.techdata['consumption-combined'] || it.configuration.techdata['carbon-dioxide-emissions'] || it.configuration.techdata['efficiency-class'])}}
						{{? it.configuration.techdata['consumption-combined']}}
							<dl class="audi-copy-s search-audicode__disclaimer">
								<dt class="search-audicode__disclaimer-label">{{=window.i18n['nemo.ui.consumption-combined']}}*:</dt>
								<dd class="search-audicode__disclaimer-value">{{=it.configuration.techdata['consumption-combined']}}</dd>
							</dl>
						{{?}}
						{{? it.configuration.techdata['carbon-dioxide-emissions']}}
							<dl class="audi-copy-s search-audicode__disclaimer">
								<dt class="search-audicode__disclaimer-label">{{=window.i18n['nemo.ui.emission-combined']}}*:</dt>
								<dd class="search-audicode__disclaimer-value">{{=it.configuration.techdata['carbon-dioxide-emissions']}}</dd>
							</dl>
						{{?}}
						{{? !SETUPS.get('scope.nemo.hide.eec-entry')}}
							{{? it.configuration.techdata['efficiency-class']}}
								<dl class="audi-copy-s search-audicode__disclaimer">
									<dt class="search-audicode__disclaimer-label">{{=window.i18n['nemo.ui.efficiency-class']}}:</dt>
									<dd class="search-audicode__disclaimer-value">{{=it.configuration.techdata['efficiency-class']}}</dd>
								</dl>
							{{?}}
						{{?}}
					{{?}}
				{{?}}
			</div>
		</audi-configurator-search-audicode>
	{{?}}
{{?}}`;

export {template as searchAudicodeTemplate};
