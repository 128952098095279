import {stateRegistry} from 'microkernel';

export default class SearchProvider {

	static get SEARCHPROVIDER_STORE_ID() {
		return 'dbadSearchProviderStore';
	}

	static get SEARCHQUERY_STORE_ID() {
		return 'dbadSearchQueryStore';
	}

	get searchResultStoreId() {
		return null;
	}

	constructor() {
		stateRegistry.subscribeToStore(SearchProvider.SEARCHQUERY_STORE_ID, state => {
			this.performSearch(state.searchQuery);
		});
		this.registerResultStore();
	}

	async registerResultStore() {
		if (!!this.searchResultStoreId) {
			await this.waitForProviderStore();
			stateRegistry.triggerAction(SearchProvider.SEARCHPROVIDER_STORE_ID, 'addSearchProvider', {
				searchProvider: this.searchResultStoreId
			});
		}
	}

	waitForProviderStore() {
		return new Promise((resolve) => {
			stateRegistry.subscribeToStore(SearchProvider.SEARCHPROVIDER_STORE_ID, () => {
				resolve();
			});
		});
	}

	performSearch(searchQuery) {
		throw new Error('Implement performSearch in Subclass. (searchQuery: ' + searchQuery + ')');
	}

	publishResult(searchQuery, result) {
		stateRegistry.triggerAction(this.searchResultStoreId, 'update', {
			result: result,
			searchQuery: searchQuery
		});
	}

	setPreventSubmit(preventSubmit = false) {
		stateRegistry.triggerAction(SearchProvider.SEARCHPROVIDER_STORE_ID, 'preventSubmit', {
			preventSubmit: preventSubmit
		});
	}
}
