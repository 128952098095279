(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-utils"), require("microkernel"));
	else if(typeof define === 'function' && define.amd)
		define("search-providers", ["core-utils", "microkernel"], factory);
	else if(typeof exports === 'object')
		exports["search-providers"] = factory(require("core-utils"), require("microkernel"));
	else
		root["search-providers"] = factory(root["core-utils"], root["microkernel"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__139__, __WEBPACK_EXTERNAL_MODULE__729__) => {
return 