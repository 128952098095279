import {stateRegistry} from 'microkernel';

export const SEARCHAUDICODE_STORE_ID = 'dbadAudicodeSearchStore';

class SearchAudicodeStore {
	constructor() {
		if (!SearchAudicodeStore.instance) {
			this._initStore();
			SearchAudicodeStore.instance = this;
		}
		return SearchAudicodeStore.instance;
	}

	_initStore() {
		const initialData = {result: null, searchQuery: null};
		const actions = {
			update: this._update
		};
		stateRegistry.addStore(SEARCHAUDICODE_STORE_ID, initialData, actions);
	}

	_update(state, params) {
		return {
			...state,
			result: params.result,
			searchQuery: params.searchQuery
		};
	}

}

const audicodeSearchStoreInstance = new SearchAudicodeStore();
Object.freeze(audicodeSearchStoreInstance);
