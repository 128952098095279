const template = `
<audi-configurator-search-audicode-error class="search-audicode" data-module="search-audicode">
	<div class="search-audicode__text-container search-audicode__top search-audicode__bottom">
		<p class="audi-copy-s search-audicode__error">{{=it.errorMessage}}</p>
		{{?it.pdfLink}}
			<div class="search-audicode__error-pdflink">
				<a class="audi-link-s audi-link-s--arrow-front" href="{{=it.pdfLink.url}}" target="_blank">
					<span class="audi-link-s__text">{{=it.pdfLink.label}}</span>
					<svg class="audi-link-s__icon">
						<use xlink:href="{{=SETUPS.get('coreci.url.assets')+"/icons/svg/print-small.svg#nm-icon-print-small"}}"></use>
					</svg>
				</a>
			</div>
		{{?}}
	</div>
</audi-configurator-search-audicode-error>`;
export {template as searchAudicodeErrorTemplate};
