const template = `
{{var target = SETUPS.get('scope.nemo.audicode.externallink') ? 'target="blank"' : '';}}
<audi-configurator-search-vtp-audicode class="search-audicode" data-module="search-vtp-audicode">
	<div class="search-audicode__top">
		<div class="search-audicode__background-color-container"></div>
		<div class="search-audicode__text-container">
			<div class="search-audicode__audicode audi-copy-s">{{=window.i18n['search-layer.your-audicode']}} {{=it.audicode}}</div>
			<h3 class="audi-headline-order-3 search-audicode__vtp-headline">{{=window.i18n['search-layer.available-cars']}}</h3>
			<div class="audi-copy-m search-audicode__vtp-carinfo">{{=window.i18n['search-layer.available-cars-info']}}</div>
		</div>
	</div>
	<div class="search-audicode__middle">
		<div class="search-audicode__background-color-container search-audicode__background-color-container--size60"></div>
		<div class="search-audicode__image-container search-audicode__image-container--vtp">
			<img class="search-audicode__image" src="{{=SETUPS.get('nemo.url.assets')+"/img/fallback/fb-audicode-flyout.png"}}" alt="Fallback Image">
		</div>
	</div>
	<div class="search-audicode__text-container search-audicode__bottom">
		<a href="{{=it.url}}" {{=target}} class="audi-j-quick-search-result-tracking audi-link-m audi-link-m--arrow search-audicode__link">
			<span class="audi-link-m__text">{{=window.i18n['search-layer.your-car']}}</span>
			<svg class="audi-link-m__icon">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
			</svg>
		</a>
		<p class="audi-copy-s search-audicode__reference">
			<span class="search-audicode__reference-label">{{=window.i18n['search-layer.label.disclaimer']}}: </span>
			<span class="search-audicode__reference-text">{{=window.i18n['search-layer.vtp-warning']}}</span>
		</p>
	</div>
</audi-configurator-search-vtp-audicode>
`;

export {template as searchVTPAudicodeTemplate};
