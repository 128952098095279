import {stateRegistry} from 'microkernel';

export const SEARCHQUERY_STORE_ID = 'dbadSearchQueryStore';

class SearchQueryStore {
	constructor() {
		if (!SearchQueryStore.instance) {
			this._initStore();
			SearchQueryStore.instance = this;
		}
		return SearchQueryStore.instance;
	}

	_initStore() {
		const initialData = {
			searchQuery: null
		};
		const actions = {
			clear: this._clear,
			update: this._update
		};
		stateRegistry.addStore(SEARCHQUERY_STORE_ID, initialData, actions);
	}

	_clear(state) {
		return {...state, searchQuery: null};
	}

	_update(state, params) {
		return {...state, searchQuery: params.searchQuery};
	}

}


const searchQueryStoreInstance = new SearchQueryStore();
Object.freeze(searchQueryStoreInstance);
