import {stateRegistry} from 'microkernel';

export const SEARCHPROVIDER_STORE_ID = 'dbadSearchProviderStore';

class SearchProviderStore {
	constructor() {
		if (!SearchProviderStore.instance) {
			this._initStore();
			SearchProviderStore.instance = this;
		}
		return SearchProviderStore.instance;
	}

	_initStore() {
		const initialData = {searchProviderList: [], preventSubmit: false};
		const actions = {
			addSearchProvider: this._addSearchProvider,
			preventSubmit: this._preventSubmit
		};
		stateRegistry.addStore(SEARCHPROVIDER_STORE_ID, initialData, actions);
	}

	_addSearchProvider(state, params) {
		state.searchProviderList.push(params.searchProvider);
		return state;
	}

	_preventSubmit(state, params) {
		return {...state, preventSubmit: params.preventSubmit};
	}

}

const searchProviderStoreInstance = new SearchProviderStore();
Object.freeze(searchProviderStoreInstance);

