import {template} from 'core-utils';

import SearchProvider from '../search-provider/search-provider';
import {SEARCHAUDICODE_STORE_ID} from './search-audicode-store';
import {searchAudicodeTemplate} from './search-audicode-template';
import {searchVTPAudicodeTemplate} from './search-vtp-audicode-template';
import {searchAudicodeErrorTemplate} from './search-audicode-error-template';
import {fetchImageFromRenderAPI} from '../../docroot/cms4i-nemo/js/configurator/dpu/fetch-image-render-api';

class SearchAudicode extends SearchProvider {

	get searchResultStoreId() {
		return SEARCHAUDICODE_STORE_ID;
	}

	async performSearch(searchQuery) {
		let output = null;
		if (this.isPossibleAudicode(searchQuery)) {
			this.setPreventSubmit(true);
			try {
				const configurationResponse = await this.loadAudicode(searchQuery);
				if (configurationResponse) {
					if (this.isVTPAudicode(configurationResponse.configuration)) {
						const templateData = {
							audicode: configurationResponse.audicode,
							url: configurationResponse.configuration.audicode['other-url']
						};
						output = template.render(searchVTPAudicodeTemplate, templateData);
					}
					else {
						const images = await fetchImageFromRenderAPI(configurationResponse.configuration.configuration);
						const templateData = {
							audicode: configurationResponse.audicode,
							configuration: configurationResponse.configuration.configuration,
							mediaservice: SETUPS.get('nemo.url.mediaservice'),
							images
						};
						output = template.render(searchAudicodeTemplate, templateData);
					}
				}
			}
			catch (error) {
				const errorData = {
					errorMessage: this.getErrorMessage(error),
					pdfLink: this.getPdfLink(searchQuery, error)
				};
				output = template.render(searchAudicodeErrorTemplate, errorData);
			}
		}
		this.publishResult(searchQuery, output);
	}

	getPdfLink(audicode, error) {
		if (error.id === 'AUDICODE_CONF_NOT_VALID' && SETUPS.get('scope.nemo.audicode.not_valid_pdflink')) {
			const i18nKey = 'search-layer.audicode-pdf';
			return {
				label: (window.i18n && window.i18n[i18nKey]) ? window.i18n[i18nKey] : 'PDF anzeigen',
				url: SETUPS.get('nemo.pdf.url') + '/audicode/' + audicode
			};
		}
		return null;
	}

	isPossibleAudicode(searchQuery) {
		return (!!searchQuery && !this.isStopword(searchQuery) && this.matchesAudicodePattern(searchQuery));
	}

	isVTPAudicode(configuration) {
		return configuration.audicode && configuration.audicode.application && configuration.audicode.application === 'VTP';
	}

	isStopword(searchQuery) {
		const stopwords = SETUPS.get('nemo.search.audicodestopwords');
		if (stopwords) {
			return stopwords.split(',').includes(searchQuery);
		}
		return false;
	}

	matchesAudicodePattern(searchQuery) {
		return !!searchQuery.match(/^[Aa]{1}[a-zA-Z0-9]{7}$/);
	}

	async loadAudicode(audicode) {

		const configuration = await this.loadConfigurationByAudiCode(audicode);
		if (configuration) {
			return {
				audicode: audicode.toUpperCase(),
				configuration
			};
		}
		return null;
	}

	async loadConfigurationByAudiCode(audiCode) {
		const baseUrl = SETUPS.get('nemo.url.dpu') + 'audicode?';

		const queryParams = new URLSearchParams({
			audicode: audiCode.toUpperCase(),
			context: SETUPS.get('nemo.default.header').context
		});

		const url = baseUrl + queryParams.toString();

		return fetch(url, {credentials: 'include'})
			.then(response => {
				if (!response.ok) {
					this.setupAndThrowError({statuscode: response.status});
				}
				else {
					return response.json();
				}
			})
			.then(this.checkDPUresponseStatus.bind(this));
	}

	setupAndThrowError(data) {
		const err = new Error();
		err.status = data.statuscode;

		if (!!data.audicode && !!data.audicode.message) {
			err.name = 'custom-dpu-error';
		}

		if (!!data.header && data.header.error) {
			err.id = data.header.error.id;
			err.message = data.header.error.message ? data.header.error.message : data.header.statusmessage;
		}

		throw err;
	}

	checkDPUresponseStatus(data) {
		if (!!data.header && !!data.header.statuscode) {
			const sCode = Math.floor(data.header.statuscode / 100);
			if (sCode === 3 || sCode === 5) {
				this.setupAndThrowError(data);
			}
		}
		return data;
	}

	getErrorMessage(error) {
		const i18nErrorMessage = this.getErrorMessageFromI18N(error);
		const errorMessage = i18nErrorMessage ? i18nErrorMessage : error.message;

		return errorMessage ? errorMessage : this.getErrorMessageFromI18N('DEFAULT');
	}

	getErrorMessageFromI18N(error) {
		const errorCode = typeof error === 'string' ? error : error.id;
		return window.i18n['error.message.' + errorCode];
	}
}

const searchAudicode = new SearchAudicode();
export {searchAudicode, SearchAudicode};
