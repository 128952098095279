import api_config from './config.json';

const EXCLUSIVE_EXTERIOR_COLOR_CODE_PREFIX = 'LC_PR';

const MAPPINGS = {
	"exterior": {
		fullscreen: 'aecwn8o1920x1152',
		flyout: 'aecwn8o1920x600'
	},
	"exterior_front": {
		fullscreen: 'aecwn1o1920x1152',
		flyout: 'aecwn1o1920x600'
	},
	"exterior_back": {
		fullscreen: 'aecwn6o1920x1152',
		flyout: 'aecwn6o1920x600'
	},
	"exterior_straight_front": {
		fullscreen: 'aecwn3o1920x1152',
		flyout: 'aecwn3o1920x600'
	},
	"exterior_straight_back": {
		fullscreen: 'aecwn5o1920x1152',
		flyout: 'aecwn5o1920x600'
	},
	"closeup_lights": {
		fullscreen: 'aecwn2o1920x1152',
		flyout: 'aecwn2o1920x600'
	},
	"interior": {
		fullscreen: 'aecwi1n1920x1152',
		flyout: 'aecwi1n1920x600'
	},
	"dashboard": {
		fullscreen: 'aecwi2n1920x1152',
		flyout: 'aecwi2n1920x600'
	}
};

const configArray = Object.values(MAPPINGS).map((value) => { return [value?.flyout, value?.fullscreen]; });

function _getUrlFromConfig(listUrls, config) {
	if (listUrls && listUrls.length) {
		const currentConfig = listUrls.find(value => value.config === config);
		return currentConfig?.url;
	}
	return null;
}

const transformUrlToReadables = (listOfUrls) => {
	if (!listOfUrls) {
		return [];
	}

	return Object.entries(MAPPINGS).reduce((acc, value) => {
		return {
			...acc,
			[value[0]]: {
				"fullscreen": _getUrlFromConfig(listOfUrls, value[1]?.fullscreen),
				"flyout": 	_getUrlFromConfig(listOfUrls, value[1]?.flyout)
			}
		};
	}, {});
};

const isExclusiveColor = (exteriorColor) => {
	return exteriorColor?.startsWith(EXCLUSIVE_EXTERIOR_COLOR_CODE_PREFIX);
};

// eslint-disable-next-line max-statements
export const fetchImageFromRenderAPI = async function (configuration){
	const aveString = configuration?.ave;
	const mbvIds = configuration && configuration["mbv-ids"];
	if (!aveString){
		return Promise.reject({});
	}

	const aveArray = aveString.split(',');
	const modelAndColorDetail = aveArray.slice(0, 5);
	const standardEquipments = aveArray.slice(5);
	const aveExtColor = aveArray[3];
	const aveIntColor = aveArray[4];
	const aveModelYear = aveArray[2];
	const aveModel = aveArray[1];
	const specialExteriorColor = configuration?.extColorCode?.selected;
	const exteriorColor = mbvIds?.exteriorColor || aveExtColor;
	const interiorColor = mbvIds?.interiorColor || aveIntColor;
	const modelYear = mbvIds?.modelyear || configuration?.modelyear || aveModelYear;
	const modelCode = aveModel || mbvIds?.model || configuration?.model;

	const requestBody = {
		client: api_config.mediaService.clientId,
		brand: 'ALL',
		configs: [].concat(...configArray),
		live: api_config.mediaService.live,
		modelRequests: [{
			modelCode,
			modelYear,
			exteriorColor,
			interiorColor,
			standardEquipments,
			"mimeType":"image/png",
			...(isExclusiveColor(modelAndColorDetail[3]) && {"aozEquipments": [specialExteriorColor]})
		}]
	};

	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(requestBody)
	};

	try {
		const response = await fetch(api_config.mediaService.api, options);
		if (!response.ok) {
			console.error('Response error from fetch images API: ', response);
		}
		else {
			return response.json().then(resp => {
				const listOfUrls = resp.modelResults[0]?.urlResult.filter(entry => entry?.url);
				return transformUrlToReadables(listOfUrls);
			});
		}
	}
	catch (err) {
		console.error('error occured when trying to fetch images from API:' + err);
	}
};
